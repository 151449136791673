import React from "react";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./moreInfoSection.css";
const MoreInfoSection = () => {
  return (
    <section
      className={"sec sec1MoreInfo hero-sec"}
    >
      <div className="container p-0 py-5 px-5 px-md-0">
        <div className="row d-flex align-items-center justify-content-center my-3">
          <div className="col-12 order-sm-1 order-2">
            <AnimationOnScroll
              className={"lead-text"}
              delay={300}
              animateOnce={true}
              animateIn="animate__fadeInLeft"
            >
              <div className=" ms-lg-2 ms-0 d-flex justify-content-center align-items-center flex-column">
                <img className="img-fluid" src={require("../../../images/logo.png")} width={700} height="auto"/>
                <p className="text-light text-center fs-4 my-4 fw-bold">
                  Providing 100+ DataCamp Accounts every year worth more than 10,000+ USD
                </p>
                <div className="d-flex justify-content-center align-items-center" style={{width:"100%"}}>
                  <a
                    href="https://www.datacamp.com/donates"
                    target="_blank"
                    type="button"
                    id="herobtn"
                    className="btn btn-lg text-light text-center"
                  >
                    Get More Information
                  </a>
                </div>

              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MoreInfoSection;
