import React from "react";
import { Link } from "react-router-dom";
import { OUR_TEAMS_PAGE } from "../../constants/string_constants";
import "./About.css";
import "./OurTeamsPage.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import Banner from "../../components/banner/Banner";

export default function OurTeamsPage() {
  return (
    <div className={"ourTeamsPage our-teams-page"}>
      <Banner
        title="Our Teams"
        titleClasses="ourTeams-banner"
        img={OUR_TEAMS_PAGE.banner.img}
      />
      <ScrollAnimation
        animateIn={"fadeInRight"}
        className={"sec-1"}
        animateOnce={true}
      >
        <div className={"image"}>
          <img src={OUR_TEAMS_PAGE.secOne.img} alt={"our-teams"} />
        </div>
        <div className={"text-container"}>
          <h1>{OUR_TEAMS_PAGE.secOne.title}</h1>
          <p>{OUR_TEAMS_PAGE.secOne.para}</p>
        </div>
      </ScrollAnimation>
      <section className={"sec-2"}>
        <h1>Our Teams</h1>
        <h6>
          A Total Of 10 Teams Function Under The AI Club. From Content Writing
          To Marketing To Technical Etc, Each Team Consists Of Dedicated And
          Skilled Individuals
        </h6>
        <div className={"images"}>
          {OUR_TEAMS_PAGE.secTwo.map((obj, i) => (
            <ScrollAnimation
              key={i}
              animateIn={"fadeInDown headShake"}
              className={"image-container"}
              animateOnce={true}
              delay={i * 100}
            >
              <div className={"team-name"}>{`${obj.team}`}</div>
              <Link
                onClick={async () => {
                  window.scrollTo(0, 0);
                  window.location.assign(`our-teams/${obj.path}`);
                }}
              >
                <div className={"overlay"} />
                <img src={obj.image} alt={obj.team} />
              </Link>
            </ScrollAnimation>
          ))}
        </div>
      </section>
    </div>
  );
}
