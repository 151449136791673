import React, { useEffect, useState } from "react";
import "./About.css";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { ABOUT_PAGE } from "../../constants/string_constants";
import {
  img_aboutUs_desktop,
  img_aboutUs_mobile,
} from "../../constants/image_constants";

import "./AboutPage.css";
import { ImageSlider } from "../../components/imageSlider/ImageSlider";
import Banner from "../../components/banner/Banner";
import { checkMediaQuery } from "../../utils/dimensions.utils";
import { BANNER_BLUE } from "../../constants/color_constants";
export default function AboutPage() {
  const [isMobileView, setIsMobileView] = useState(checkMediaQuery().matches);

  useEffect(() => {
    checkMediaQuery().addEventListener("change", (e) => {
      setIsMobileView(e.matches);
    });
  }, []);

  return (
    <div className={"aboutPage"}>
      <Banner
        title={"About Us"}
        titleClasses="aboutUs-banner"
        img={{ desktop: img_aboutUs_desktop, mobile: img_aboutUs_mobile }}
      />
      <section
        className={"sec-1 up-1"}
        style={{ backgroundColor: BANNER_BLUE }}
      >
        {ABOUT_PAGE.secOne.map((card, index) => {
          const flexDirectionClass =
            index % 2 !== 0 ? "flex-row-reverse" : "flex-row";
          const animateIn =
            index % 2 !== 0 ? "animate__fadeInLeft" : "animate__fadeInRight";
          return (
            <AnimationOnScroll
              animateOnce={true}
              animateIn={animateIn}
              className={`card-container ${
                !isMobileView && flexDirectionClass
              }`}
            >
              <div className={"img-container"}>
                <img alt={card.title} src={card.image} />
              </div>
              <div className={"text-container"}>
                <h3>{card.title}</h3>
                <p>{card.para}</p>
              </div>
            </AnimationOnScroll>
          );
        })}
      </section>
      <section className={"sec-2 up-1"}>
        <AnimationOnScroll animateOnce={true} animateIn={"animate__fadeInLeft"}>
          <div className="text-container">
            <h1>{ABOUT_PAGE.secTwo.title}</h1>
            <h4>{ABOUT_PAGE.secTwo.subTitle}</h4>
            <p>{ABOUT_PAGE.secTwo.para}</p>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn">
          <div className="img-container">
            <img
              src={ABOUT_PAGE.secTwo.img}
              alt={ABOUT_PAGE.secTwo.title}
              className="img-style"
            />
          </div>
        </AnimationOnScroll>
      </section>
      <section className={"sec-3"}>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={"animate__fadeInLeft"}
          className={"text-container"}
        >
          <h2 style={{ fontWeight: "bold", color: "#44699d" }}>
            {ABOUT_PAGE.secThree.title}
          </h2>
          <br />
          <p style={{ whiteSpace: "pre-line" }}>{ABOUT_PAGE.secThree.para}</p>
          <p>{ABOUT_PAGE.secThree.name}</p>
        </AnimationOnScroll>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={"img-container"}
        >
          <img
            alt={"sec-5-img"}
            src={ABOUT_PAGE.secThree.img}
            style={{
              objectFit: "contain",
              maxWidth: "100%",
              marginTop: "-90px",
            }}
          />
        </div>
      </section>
      <section className={"sec-4"}>
        <h2>{ABOUT_PAGE.secFour.title}</h2>
        <h6 style={{ textAlign: "center" }}>{ABOUT_PAGE.secFour.para}</h6>
        <div className="img-slider">
          <ImageSlider />
        </div>
      </section>
      <section className={"sec-5"}>
        <div className="container">
          <div className="row text-center">
            <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn">
              <div className="col-sm-12 col-md-12 mb-4">
                <h2 className="text-center mt-4" style={{ fontWeight: "bold" }}>
                  Our Excom Body
                </h2>
              </div>
            </AnimationOnScroll>
            <div className="images">
              {ABOUT_PAGE.secFive.images.map((obj, i) => (
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInLeft"}
                  className={"item-container"}
                  key={`excom-${i}`}
                >
                  <div className="img-container">
                    <img alt={obj.name} src={obj.src} />
                  </div>
                  <div className="text-container">
                    <h4>{obj.name}</h4>
                    <h6>{obj.position}</h6>
                  </div>
                </AnimationOnScroll>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={"sec-6"}>
        <div className="container">
          <div className="row text-center">
            <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn">
              <div className="col-sm-12 col-md-12 mb-4">
                <h2 className="text-center mt-4" style={{ fontWeight: "bold" }}>
                  Our Chairs
                </h2>
              </div>
            </AnimationOnScroll>
            <div className="images">
              {ABOUT_PAGE.secSix.images.map((obj, i) => (
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInLeft"}
                  className={"item-container"}
                  key={`chair-${i}`}
                >
                  <div className="img-container">
                    <img alt={obj.name} src={obj.src} />
                  </div>
                  <div className="text-container">
                    <h4>{obj.name}</h4>
                    <h6>{obj.position}</h6>
                  </div>
                </AnimationOnScroll>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
