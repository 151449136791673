import { logout } from "../redux/slices/user.slice";

/*
  |------------------------------------------------|
  |            Navigation Routes                   |
  |------------------------------------------------|
  | In this constant, the defualt (signed out) and signed in routes are
  | listed. These are arrays of objects within the main constant "navRoutes".
  | Each object in the array holds the name, path, drop down routes, and function
  | property that will be used as required when mapping the array in 
  | Navbar.js and Drawer.js.
*/


export const navRoutes = {
  default: [
    { name: "Home", path: "/" },
    { name: "Blogs", path: "/blogs" },
    
    //{ name: "Courses", path: "/courses" },
    { name: "Projects", path: "/projects" },
    { name: "Membership", path: "/membership" },
    // {
    //   name: "Register",
    //   path: "/student-registeration",
    //   dropdownRoutes: [
    //     { name: "As Student", path: "/student-registeration" },
    //     { name: "As Instructor", path: "/instructor-registeration" },
    //   ],
    //   dropdownIndex: 1,
    // },
    {
      name: "Events",
      path: "/events",
    },
    {
      name: "About",
      path: "/about",
      dropdownRoutes: [
        {name: "About Us", path: "about"},
        { name: "Our Teams", path: "our-teams" },
      ],
      dropdownIndex: 0,
    },
    // {
    //   name: "Sign In",
    //   path: "/sign-in",
    // },
    { name: "Contact Us", path: "/contact-us" },
  ],
  signedIn: [
    { name: "Home", path: "/home" },
    { name: "Blogs", path: "/blogs" },
    {
      name: "About",
      path: "/about",
      dropdownRoutes: [
        { name: "Our Teams", path: "our-teams" },
        { name: "Events", path: "events" },
        { name: "Gallery", path: "gallery" },
      ],
      dropdownIndex: 0,
    },
    { name: "Courses", path: "/courses" },
    { name: "Projects", path: "/projects" },
    { name: "Membership", path: "/membership" },
    {
      name: "My Account",
      path: "/dashboard",
      dropdownRoutes: [
        {
          name: "Log Out",
          path: "/",
          func: function (dispatch) {
            dispatch(logout());
          },
        }, //this will not be a navigation route but a logout button
      ],
      dropdownIndex: 2,
    },
    { name: "Contact Us", path: "/contact-us" },
  ],
};


