import { get, post } from "../axiosConfig";
// services object that handle Blog APIs
// each propery in the object is a function that uses the axios http request method (get, post, put, etc)
const blogServices = {
  postBlog: ({ blog, accessToken }) => {
    return post("blogs/post/", blog, {
      headers: { Authorization: accessToken },
    });
  },
  getBlogList: ({page, size}) => {
    return get("blogs", {
      params: { page, size },
    });
  },
  getBlog: (id) => {
    return get(`blogs/${id}`);
  },
};

export default blogServices;
