import React, { useEffect } from "react";
import { useState } from "react";
import { BANNER_BLUE } from "../../constants/color_constants";
import { default_banner_img } from "../../constants/image_constants";
import { checkMediaQuery } from "../../utils/dimensions.utils";
import "./Banner.css";

const Banner = ({ title, img = default_banner_img, titleClasses }) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(orientation: portrait), (max-width: 500px)").matches
  );

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    checkMediaQuery().addEventListener("change", handler);
  });

  return (
    <section
      className={"banner"}
      style={{
        backgroundImage: `url(${
          (matches && img.mobile) || img.desktop || img
        })`,
        color: BANNER_BLUE,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          height: "100%",
        }}
      >
        <h1 className={titleClasses || "banner-title"}>{title}</h1>
      </div>
    </section>
  );
};

export default Banner;
