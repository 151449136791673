import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './ImageSlider.css';
import { OUR_TEAMS } from "../../constants/image_constants";

export function ImageSlider() {
        const settings = {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          // autoplay: true,
          autoplaySpeed: 2000,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }]
        };
    return (
      <div className="imgSlide">
        <Slider ref={slider => (slider = slider)} {...settings}>
        {OUR_TEAMS.map((item) => (
            <div className='img-slider'>
              <div className='img-slidercard-top'
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                                >
                <img src={item.image} alt={item.title}/>
              </div>
            </div>
        ))}
        </Slider>
      </div>
    );
}
// export default playplay;

// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import React, { Component } from "react";

// import './App.css';
// import { OUR_TEAMS } from "../../constants/image_constants";

// export default class playplay extends Component {
//     render() {
//       const settings = {
//         dots: true,
//         infinite: true,
//         slidesToShow: 2,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 2000
//       };
  
//   return (
//     <div className="App">
//       <Slider ref={slider => (this.slider = slider)} {...settings}>
//       {OUR_TEAMS.map((item) => (
//           <div className='card'>
//             <div className='card-top'>
//               <img src={item.image} alt={item.title}/>
//             </div>
//           </div>
//       ))}
//       </Slider>
//     </div>
//   );
// }}