import React from "react";
import "animate.css";
import "./projects.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { IMAGES_HOMEPAGE } from "../../../constants/image_constants";
const Projects = () => {
  const projectsList = [
    {
      id: 0,
      title: "Text Detector",
      summary:
        "Using the TensorFlow, Anaconda, and the Keras API, this application identifies both the presence and the orientation of cows in images.",
      route: "projects",
      img: IMAGES_HOMEPAGE.PROJECTS.text_detector,
    },
    {
      id: 1,
      title: "Object Detector",
      summary:
        "Is it a flower? A watch? A laptop? Or a notebook? Let AI Club’s Object Detector App help you find out!",
      route: "projects",
      img: IMAGES_HOMEPAGE.PROJECTS.object_detector,
    },
    {
      id: 2,
      title: "Cow App",
      
      summary:
        "Using the TensorFlow, Anaconda, and the Keras API, this application identifies both the presence and the orientation of cows in images.",
      route: "projects",
      img: IMAGES_HOMEPAGE.PROJECTS.cow_detector,
    },
  ];

  return (
    <div className="sec3">
      <section className="projects-sec pt-5 pb-5">
        <AnimationOnScroll
          animateOnce={true}
          animateIn="animate__zoomIn"
          className="header-container"
        >
          <div className="container mt-5 mb-5">
            <div className="text-container">
              <h2 className="fw-bold text-center">Our Projects</h2>
              <h3 className="text-center fs-5">
                We work on ambitious projects solutions and enhance the standards
                of living through technology.
              </h3>
            </div>
            
            <div
              className={"list-container"}
            >
              {projectsList.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="project-parent"
                  >
                    <div
                      className="card project-card projects_card_image"
                      style={{ backgroundImage: `url(${item.img})` }}
                    >
                      <div
                        className="card-body d-flex flex-column align-items-center text-center"
                        style={{ zIndex: "300", borderRadius: "10px" }}
                      >
                        <h4 className="card-title text-light fw-bold mt-auto">
                          {item.title}
                        </h4>
                        <p className="card-text text-light my-4">
                          {item.summary}
                        </p>
                        <a
                          href={item.route}
                          className="btn btn-light mb-3"
                          style={{ color: "#44699d" }}
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </AnimationOnScroll>
      </section>
    </div>
  );
};

export default Projects;
