import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { drawerToggle, closeDrawer } from "../../redux/slices/appStates.slice";
import { NavLink } from "react-router-dom";
import "./Drawer.css";
import { navRoutes } from "../../constants/route_constants";
// import { useSwipeable } from "react-swipeable";

const Drawer = ({ isOpen }) => {
  // const [icon, setIcon] = useState("down");
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState([false, false, false]);
  // const [icon,setIcon]=useState([false,false])
  const user = useSelector((state) => state.user.data);
  // const swipeHandler = useSwipeable({
  //   onSwipedLeft: () => {
  //     dispatch(drawerToggle());
  //     document.body.style.overflow = "auto";
  //   },
  // });
  const toggler = () => {
    dispatch(drawerToggle());
  };
  // const handleDrawerClicked = (e) => {
  //   e.stopPropagation();
  // };

  const RenderRouteList = ({ routeList }) => {
    return routeList.map((route, index) => {
      return (
        <div key={index} className={"routeItem-container"}>
          <div
            className={"routeItem bg"}
            onClick={() => {
              if (route.dropdownRoutes) {
                window.scrollTo(0, 0);
                //check if route has a drop down
                let newArray = [...dropdownOpen]; // copy
                newArray[route.dropdownIndex] = !newArray[route.dropdownIndex];
                // setDropdownOpen(newArray);
              }
            }}
          >
            <NavLink
              onClick={(e) => {
                e.preventDefault();
                window.scrollTo(0, 0);
                dispatch(closeDrawer());
                document.body.style.overflow = "auto";
                window.location.replace(route.path);
                // setDropdownOpen([false, false, false]);
              }}
              to={route.path}
              className="Link"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "rgba(204, 232, 255, 0.8)" : "",
                  color: isActive ? " #0275d8" : "",
                };
              }}
            >
              {route.name}
            </NavLink>
            {route.dropdownRoutes ? (
              <i
                onClick={() => {
                  // setIcon(icon==="down"?"up":"down")
                  if (route.dropdownRoutes) {
                    // if(route.name==="About"){setIcon_About(icon_about==="down"?"up":"down")}else if(route.name==="Events"){setIcon_Event(icon_event==="down"?"up":"down")}
                    let newArray = [...dropdownOpen];
                    newArray[route.dropdownIndex] =
                      !newArray[route.dropdownIndex];

                    setDropdownOpen(newArray);
                  }
                }}
                className={`bi bi-chevron-${
                  dropdownOpen[route.dropdownIndex] ? "up" : "down"
                }`}
                style={{
                  width: "15%",
                  alignSelf: "stretch",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : null}
          </div>
          {route.dropdownRoutes ? (
            <div
              className={`dropdownRoutes ${
                dropdownOpen[route.dropdownIndex]
                  ? "dropdownOpen"
                  : "dropdownClose"
              }`}
            >
              {route.dropdownRoutes.map((dropdownRoute, index) => {
                return (
                  <NavLink
                    onClick={(e) => {
                      e.preventDefault();
                      // setDropdownOpen([false, false, false]);
                      window.scrollTo(0, 0);
                      dispatch(closeDrawer());
                      document.body.style.overflow = "auto";

                      if (dropdownRoute.func) {
                        dropdownRoute.func(dispatch);
                      }
                      window.location.replace(route.path);
                    }}
                    key={index}
                    to={
                      dropdownRoute.path == "/dashboard"
                        ? `${dropdownRoute.path}/${user._id}`
                        : dropdownRoute.path
                    }
                    className="dropdownItems"
                    style={({ isActive }) => {
                      return {
                        backgroundColor: isActive
                          ? "rgba(204, 232, 255, 0.8)"
                          : "",
                        color: isActive ? " #0275d8" : "",
                      };
                    }}
                  >
                    {dropdownRoute.name}
                  </NavLink>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    });
  };

  return (
    <SwipeableDrawer open={isOpen} onClose={toggler} onOpen={toggler}>
      <div className={"drawer-routeList drawer_container_open"}>
        <div className="w-100 d-flex">
          <i
            className="bi bi-x-lg m-2 ms-auto mb-0 text-dark fs-3 fw-bolder"
            onClick={toggler}
          ></i>
        </div>
        <hr></hr>
        <div style={{ height: "4vh" }}>
          <RenderRouteList
            routeList={user._id ? navRoutes.signedIn : navRoutes.default}
          />
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default Drawer;
