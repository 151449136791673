import React from "react";
import "animate.css";
import "./sponsors.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { SPONSORS } from "../../../constants/image_constants";

const Sponsors = () => {
  return (
    <section className="sponsors sec7">
      {/* <div className="text-end mx-auto"><a href="#" className="btn bg-none ms-auto me-4">View All</a></div> */}
      <h2 className="fw-bold text-center pb-2">Our Sponsors</h2>
      <div className="row mx-auto p-0 card-list">
        {SPONSORS.map((item, index) => {
          return (
            <div
              key={`${item.name + index}`}
              className="col-lg-4 col-md-6 mb-3 col-12"
            >
              <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn">
                <div className="sponsor-card" style={{ borderRadius: "5px" }}>
                  {/* <div className="-dark blog-card-img rounded-3" style={{backgroundImage:`url(${home2})`}}></div> */}
                  <div className="card-body text-start">
                    {/* <div className=""><i className="bi bi-calendar-event text-primary"></i> <span className="text-muted fw-bold" style={{fontSize:"13px"}}>September 21, 2022</span></div> */}
                    <img className="logo" src={item.logo} />
                    {/* <h5 className="card-title fw-bold my-2">No Data</h5>
                <p className="card-text fw-bold text-muted py-0">No Data<br/></p> */}
                    {/* <p className="text-end text-primary fs-5"><i className="bi bi-hand-thumbs-up mx-1"></i> <i className="bi bi-share"></i></p> */}
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Sponsors;
