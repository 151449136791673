import React from "react";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { IMAGES_HOMEPAGE } from "../../../constants/image_constants";
import "./aicwork.css";
const AicWork = () => {
  const HOW_AIC_WORKS = {
    title: "How AI Club Works",
    para: "The AI Club brings the young AI startups into spotlight linking them with potential investors",
    list: [
      {
        heading: "AI Festival",
        text: "Provides enthusiasts the oppurtunity to engage in AI related activities, seminars and contests",
        img: IMAGES_HOMEPAGE.HOW_AIC_WORKS.aic_festival,
      },
      {
        heading: "Bootcamp",
        text: "Training camps and programmes created to help individuals gain first-hand experience in AI technologies ",
        img: IMAGES_HOMEPAGE.HOW_AIC_WORKS.bootcamp,
      },
      {
        heading: "Courses",
        text: "Online and physical learning oppurtunities for individuals to gain knowledge on AI related subjects",
        img: IMAGES_HOMEPAGE.HOW_AIC_WORKS.courses,
      },
    ],
  };

  return (
    <section className="aic-work-sec sec4 ">
      <div className="container ">
        <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn">
          <h2 className="fw-bold text-center">{HOW_AIC_WORKS.title}</h2>
          <p className="text-center fs-5">{HOW_AIC_WORKS.para}</p>
        </AnimationOnScroll>
        <div className="card-list-container row w-100 mx-auto">
          {HOW_AIC_WORKS.list.map((item, index) => {
            return (
              <div
                key={`${item.heading}-${index}`}
                className="aic-work-card-container"
              >
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={`${
                    index === 0
                      ? "animate__fadeInLeft"
                      : index === 2
                      ? "animate__fadeInRight"
                      : "animate__zoomIn"
                  }`}
                  className={"aic-work-card col-lg-4 col-12 p-0 mb-lg-0 mb-0"}
                >
                  <div className="card-circle">
                    {/* <img src={brain} className="doodle-img mx-auto border rounded-circle" /> */}
                    {/* <div className="-danger rounded-overlay"></div> */}
                    <div className="text-div">
                      <div className="img-container rounded-overlay">
                        <img
                          src={item.img}
                          alt={item.heading}
                          className="doodle-img mx-auto rounded-circle"
                        />
                      </div>
                      <div className="text-container text mt-3 mt-sm-4">
                        <h4 className="fw-bold" style={{ color: "white" }}>
                          {item.heading}
                        </h4>
                        <p className="text-wrap mb-0 mt-1">
                          {item.text}
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AicWork;
